<div class="form-wrapper step1">
  <div class="form-group">
    <label class="control-label">Country</label>
    <ng-select
      [(ngModel)]="countryLanguageData.chosenCountry"
      [readonly]="applicationId"
      [compareWith]="compareObjectsById"
      placeholder="{{countryLanguageData.chosenCountry ? '' : 'Find country'}}">
      <ng-container *ngIf="!applicationId">
        <ng-option *ngFor="let country of countryLanguageData.countries" [value]="country">{{country.name}}</ng-option>
      </ng-container>
      <ng-container *ngIf="applicationId">
        <ng-option [value]="countryLanguageData.chosenCountry">{{countryLanguageData.chosenCountry.name}}</ng-option>
      </ng-container>
    </ng-select>
  </div>
  <div class="form-group">
    <label class="control-label">Application Language</label>
    <ng-select
      [(ngModel)]="countryLanguageData.chosenLanguages"
      [multiple]="true"
      (add)="addLanguageToDocumentsGroup($event)"
      (remove)="removeLanguageFromDocumentsGroup($event.value)"
      [compareWith]="compareObjectsById"
      placeholder="{{countryLanguageData.chosenLanguages.length ? '' : 'Find language'}}">
      <ng-option *ngFor="let item of countryLanguageData.languages | sort:'name'" [value]="item">{{item.name}}</ng-option>
    </ng-select>
  </div>
  <div class="form-group">
    <a href="https://roche.service-now.com/rose?id=nr_sc_cat_item&sys_id=5857ee861bfd955c975c99baab4bcb54" target="_blank"><b>Request new language <i
      class="fa fa-question-circle-o" aria-hidden="true"></i></b></a>
  </div>
  <small>Documents</small>
  <ul>
    <li>
      <button class="btn btn-outline" [disabled]="!countryLanguageData.chosenCountry?.id || !countryLanguageData.chosenLanguages.length" (click)="openModal('terms')">+ Add Terms</button>
    </li>
    <li>
      <button class="btn btn-outline" [disabled]="!countryLanguageData.chosenCountry?.id || !countryLanguageData.chosenLanguages.length" (click)="openModal('imprint')">+ Add Imprint Information</button>
    </li>
  </ul>
  <button class="btn btn-raised btn-info btn-next" [disabled]="!validateFirstStep()" (click)="save()">
    Save & go next
  </button>
  <div *ngIf="validationMessage">{{validationMessage}}</div>
</div>
