import { NgModule } from '@angular/core';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ApplicationsSetupComponent } from './applications-setup/applications-setup.component';
import { ApplicationsComponent } from './applications/applications.component';
import { ConfirmDeleteModalComponent } from './applications/confirm-delete-modal/confirm-delete-modal.component';
import { CountryLanguageComponent } from './applications-setup/applications-setup-steps/1-country-language/country-language.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { RolesPermissionsComponent } from './applications-setup/applications-setup-steps/2-roles-permissions/roles-permissions.component';
import { DocumentsModalComponent } from './applications-setup/applications-setup-steps/documents-modal/documents-modal.component';
import { ValidationComponent } from './applications-setup/applications-setup-steps/3-validation/validation.component';
import { HelpContactsComponent } from './applications-setup/applications-setup-steps/4-help-contacts/help-contacts.component';
import { DatabaseComponent } from './applications-setup/applications-setup-steps/5-database/database.component';
import { SummaryComponent } from './applications-setup/applications-setup-steps/6-summary/summary.component';
import { ConfirmDeleteHelpContactModalComponent } from './applications-setup/applications-setup-steps/4-help-contacts/confirm-delete-help-contact-modal/confirm-delete-help-contact-modal.component';
import { MedicineModalComponent } from './applications-setup/applications-setup-steps/5-database/medicine-modal/medicine-modal.component';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';
import { ConfirmReleaseModalComponent } from './applications-setup/applications-setup-steps/6-summary/confirm-release-modal/confirm-release-modal.component';
import { DecommissionComponent } from './decommission/decommission.component';
import { ValidationModalComponent } from './applications-setup/applications-setup-steps/3-validation/validation-modal/validation-modal.component';
import { FilterStatusPipe } from './user-management/filter.pipe';
import {
  ConfirmHideModalComponent
} from './applications-setup/applications-setup-steps/6-summary/confirm-hide-modal/confirm-hide-modal.component';

@NgModule({
  declarations: [
    HomeComponent,
    ApplicationsSetupComponent,
    ApplicationsComponent,
    ConfirmDeleteModalComponent,
    CountryLanguageComponent,
    RolesPermissionsComponent,
    DocumentsModalComponent,
    RolesPermissionsComponent,
    ValidationComponent,
    HelpContactsComponent,
    DatabaseComponent,
    SummaryComponent,
    ConfirmDeleteHelpContactModalComponent,
    MedicineModalComponent,
    ConfirmReleaseModalComponent,
    ConfirmHideModalComponent,
    ValidationModalComponent,
    DecommissionComponent,
    FilterStatusPipe
  ],
  imports: [
    SharedModule,
    HomeRoutingModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxFileDragDropModule],
  exports: [
    FilterStatusPipe
  ]
})
export class HomeModule {
}
