import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-release-modal',
  templateUrl: './confirm-hide-modal.component.html'
})
export class ConfirmHideModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {}
}
