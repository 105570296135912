import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from './core/services';

@Component({
  selector: 'app-root',
  template: `<app-loader *ngIf="loading"></app-loader>
    <router-outlet></router-outlet> `,
})
export class AppComponent implements AfterViewInit, OnDestroy {
  loading: boolean;
  private loading$: Subscription;

  constructor(
    private loadingService: LoadingService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.loading$ = this.loadingService.loading$
      .pipe()
      .subscribe((status: boolean) => {
        this.loading = status;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.loading$.unsubscribe();
  }
}
