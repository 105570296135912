<div class="container-fluid">

  <app-loader *ngIf="loading" class="loader" style="width: 80%"></app-loader>

  <div class="row-fluid">
    <div class="application-status col-md-12" *ngIf="this.applicationId">
      Edit Application in Status <span class="{{isReleased ? 'RELEASED' : 'DRAFT'}}">{{isReleased ? 'RELEASED' : 'DRAFT'}}</span>
      <div *ngIf="isReleased"><i class="fa fa-exclamation-circle" aria-hidden="true"></i>All saved changes will be visible for the users.
      </div>
    </div>
    <div [hidden]="loading" class="wizard col-md-12">
      <div class="wizard-wrapper">
        <div class="vertical-border"></div>
        <div [hidden]="loading">
          <ul class="wizard-pills">
            <li [class.current]="stepNumber === 1">
              <div>
                <p>
                  <span>1</span>
                </p>
              </div>
              <h4 class="step-header">Country &amp; Language</h4>
              <div class="clear"></div>
              <app-country-language *ngIf="stepNumber === 1"></app-country-language>
            </li>
            <li [class.current]="stepNumber === 2">
              <div>
                <p>
                  <span>2</span>
                </p>
              </div>
              <h4 class="step-header">Roles & Permissions</h4>
              <div class="clear"></div>
              <app-roles-permissions *ngIf="stepNumber === 2"></app-roles-permissions>
            </li>
            <li [class.current]="stepNumber === 3">
              <div>
                <p>
                  <span>3</span>
                </p>
              </div>
              <h4 class="step-header">Validation</h4>
              <div class="clear"></div>
              <app-validation *ngIf="stepNumber === 3"></app-validation>
            </li>
            <li [class.current]="stepNumber === 4">
              <div>
                <p>
                  <span>4</span>
                </p>
              </div>
              <h4 class="step-header">Help Contacts</h4>
              <div class="clear"></div>
              <app-help-contacts *ngIf="stepNumber === 4"></app-help-contacts>
            </li>
            <li [class.current]="stepNumber === 5">
              <div>
                <p>
                  <span>5</span>
                </p>
              </div>
              <h4 class="step-header">Database</h4>
              <div class="clear"></div>
              <app-database *ngIf="stepNumber === 5"></app-database>
            </li>
            <li [class.current]="stepNumber === 6">
              <div>
                <p>
                  <span>6</span>
                </p>
              </div>
              <h4 class="step-header">Summary</h4>
              <div class="clear"></div>
              <app-summary *ngIf="stepNumber === 6" [isReleased]="isReleased"></app-summary>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
