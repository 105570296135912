import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-release-modal',
  templateUrl: './confirm-release-modal.component.html'
})
export class ConfirmReleaseModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {}
}
