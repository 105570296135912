import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Medicine } from './medicine.model';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor(private httpClient: HttpClient) { }

  getMedicineData(id: string): Observable<Medicine[]> {
    const apiUrl = environment.api + `applications/${id}/medicine`;

    return this.httpClient
      .get<Medicine[]>(apiUrl);
  }

  saveMedicine(id: string, medicine: Medicine, file: any): Observable<Medicine> {
    let apiUrl = environment.api + `applications/${id}/medicine`;

    const fd = new FormData();
    const str = JSON.stringify(medicine);
    const bytes = new TextEncoder().encode(str);
    const blob = new Blob([bytes], {
      type: 'application/json;charset=utf-8'
    });

    fd.append('dto', blob);
    if (file) {
      fd.append('files', file, `${id}-${file.fileName}-${medicine.gtin}`);
    }

    if (medicine.id) {
      apiUrl += `/${medicine.id}`;
      return this.httpClient
        .put<Medicine>(apiUrl, fd);
    } else {
      return this.httpClient
        .post<Medicine>(apiUrl, fd);
    }
  }

  deleteMedicine(id: string, medicine: Medicine): Observable<Response> {
    const apiUrl = environment.api + `applications/${id}/medicine/${medicine.id}`;

    return this.httpClient
      .delete<Response>(apiUrl);
  }
}
