import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { CountryLanguageData } from './country-language.model';

@Injectable({
  providedIn: 'root'
})
export class CountryLanguageService {

  constructor(private httpClient: HttpClient) { }

  getCountryLanguageDataForNewCountry(): Observable<CountryLanguageData> {
    const apiUrl = environment.api + 'applications/country-and-region';

    return this.httpClient
      .get<CountryLanguageData>(apiUrl);
  }

  getCountryLanguageData(id): Observable<CountryLanguageData> {
    const apiUrl = environment.api + `applications/country-and-region/${id}`;

    return this.httpClient
      .get<CountryLanguageData>(apiUrl);
  }

  saveCountryLanguage(data: CountryLanguageData, id?: string): Observable<any> {
    let apiUrl = environment.api + 'applications/country-and-region';
    if (id) {
      apiUrl += `/${id}`;
    }

    const fd = new FormData();
    const str = JSON.stringify(data);
    const bytes = new TextEncoder().encode(str);
    const blob = new Blob([bytes], {
      type: 'application/json;charset=utf-8'
    });

    fd.append('dto', blob, 'dto');
    data.documents.imprint.forEach((el) => {
      fd.append('files', el.file, el.fileName);
    });
    data.documents.terms.forEach((el) => {
      fd.append('files', el.file, el.fileName);
    });

    return this.httpClient
      .post<any>(apiUrl, fd);
  }
}
