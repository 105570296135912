import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Oauth2Service } from '../../core/services/oauth2/oauth2.service';
import { ConfirmDeleteModalComponent } from './confirm-delete-modal/confirm-delete-modal.component';
import { ApplicationsService } from './applications.service';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit {
  applications: any;
  isMain: any;

  constructor(private route: ActivatedRoute,
              private oauth2Service: Oauth2Service,
              private applicationsService: ApplicationsService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.applicationsService.getApplicationList().subscribe((apps) => this.applications = apps);
    this.isMain = this.oauth2Service.getProfile().isMain;
  }

  confirmDeletingApplication(item) {
    const dialogRef = this.dialog.open(ConfirmDeleteModalComponent, {
      data: {
        countryName: item.name
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.applicationsService.deleteApp(item).subscribe(() => {
          this.applications.splice(this.applications.findIndex(elem => elem.id === item.id), 1);
        });
      }
    });
  }
}
