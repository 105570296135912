<div class="form-wrapper step4">
  <form [formGroup]="helpContactsForm">
    <table formArrayName="contactsArray" class="table">
      <thead>
      <tr>
        <th>Contact name</th>
        <th>Email</th>
        <th>Phone number</th>
        <th>Main Contact</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let contact of contactsArray.controls, let i = index">
        <tr [formGroupName]="i">
          <td>
            <mat-form-field>
              <input matInput type="text" formControlName="name" placeholder="Enter contact name" required>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field>
              <input matInput type="email" formControlName="email" placeholder="Enter e-mail address" email required>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field>
              <input matInput formControlName="phone" placeholder="Enter phone number"
                     type="tel" required>
            </mat-form-field>
          </td>
          <td>
            <label class="main-contact-radio">
              <input type="radio" name="primary"
                     value="true" [checked]="isPrimaryContact(i)" (click)="changeMainContact(i)"/>
              <span *ngIf="isPrimaryContact(i)" class="main-contact-message">Show in the message after verification</span>
            </label>
          </td>
          <td>
            <button mat-button (click)="deleteHelpContact(contact, i)">DELETE</button>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
    <button [disabled]="(contactsArray.length > 4 || helpContactsForm.controls.contactsArray.status === 'INVALID') && contactsArray.length !== 0"
            (click)="addNewContactRow()" class="btn btn-raised btn-success btn-new">Add new</button>
    <div class="user-registration-notify"
         *ngIf="usesEmailLogin()">
      <span class="bold">Notify about new user registration</span>
      <mat-form-field>
        <input matInput type="email" formControlName="registrationNotificationEmail" placeholder="Enter e-mail address"
               required>
      </mat-form-field>
    </div>
    <button class="btn btn-raised btn-default btn-back" (click)="back()">Back</button>
    <button class="btn btn-raised btn-default btn-next" [disabled]="helpContactsForm.status === 'INVALID'" (click)="save()">
      Save & go next
    </button>
  </form>
</div>
