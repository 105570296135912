import { Component, OnInit } from '@angular/core';
import { DatabaseService } from './database.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MedicineModalComponent } from './medicine-modal/medicine-modal.component';
import { Medicine } from './medicine.model';

@Component({
  selector: 'app-database',
  templateUrl: './database.component.html',
  styleUrls: ['./database.component.scss']
})
export class DatabaseComponent implements OnInit {
  medicinesData: Medicine[];
  applicationId: string;
  medicineSearch = '';

  constructor(private databaseService: DatabaseService,
              public dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    this.medicinesData = this.route.snapshot.data.stepData;
    this.applicationId = this.route.snapshot.params.id;
    if (!this.medicinesData) {
      this.medicinesData = [];
    }
  }

  deleteMedicine(medicine) {
    const index = this.medicinesData.indexOf(medicine);
    this.databaseService.deleteMedicine(this.applicationId, this.medicinesData[index])
      .subscribe(() => {
        this.medicinesData.splice(index, 1);
      });
  }

  save() {
    this.router.navigateByUrl(`/admin/applicationsSetup/6/${this.applicationId}`);
  }

  back() {
    this.router.navigateByUrl(`/admin/applicationsSetup/4/${this.applicationId}`);
  }

  openModal(medicine = null) {
    let index = null;
    if (medicine !== null) {
      index = this.medicinesData.indexOf(medicine);
    }

    const dialogRef = this.dialog.open(MedicineModalComponent, {
      data: {
        medicinesData: this.medicinesData,
        applicationId: this.applicationId,
        index
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.openModal();
      }
    });
  }

  validateDatabase() {
    let validationFlag = true;

    if (!this.medicinesData.length) {
      validationFlag = false;
    }

    return validationFlag;
  }
}
