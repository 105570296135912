import { Component, OnInit } from '@angular/core';
import { User } from './user.model';
import { UserManagementService } from './user-management.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  isLoading: boolean;
  userList: User[];
  filterString: string;
  constructor(private readonly userManagementService: UserManagementService) {
  }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers() {
    this.isLoading = true;
    this.userManagementService.getUserList()
      .subscribe((response) => {
        this.userList = response.content;
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      });
  }

  approveUser(user) {
    this.userManagementService.approveUser(user.id)
      .subscribe(() => {
        user.status = 'Approved';
      });
  }

  rejectUser(user) {
    this.userManagementService.rejectUser(user.id)
      .subscribe(() => {
        user.status = 'Rejected';
      });
  }

  filterUsers(filter: string) {
    this.filterString = filter;
  }
}
