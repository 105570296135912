<div class="form-wrapper step2" [formGroup]="loginMethodForm">
  <span class="clear">Permitted roles</span>
  <div formArrayName="loginMethods">
    <div class="checkboxes" *ngFor="let loginMethod of loginMethods.controls; let i = index" [class.active]="loginMethod.get('userRole.assigned').value" [formGroupName]="i">
      <div class="checkboxes-header" formGroupName="userRole">
        <mat-checkbox formControlName="assigned">{{ loginMethod.get('userRole.name').value }}</mat-checkbox>
      </div>
          <div class="checkboxes-content" *ngIf="loginMethod.get('userRole.assigned').value === true">
            <span>Access settings</span>
            <div formArrayName="authenticationProviders">
              <div *ngFor="let auth of loginMethod.get('authenticationProviders').controls; let j = index" [formGroupName]="j">
                <mat-checkbox formControlName="assigned">{{ auth.get('name').value }}</mat-checkbox>
              </div>
            </div>
            <div class="collect-information">
              <mat-checkbox formControlName="collectUserData">Collect User Information</mat-checkbox>
              <button class="btn btn-outline" [disabled]="!loginMethod.get('collectUserData').value" (click)="openPrivacyModal(loginMethod.get('collectDataDocuments'), loginMethod.get('userRole.name').value)">+ Add Privacy Policy</button>
            </div>
          </div>
    </div>
    <div>
      <button class="btn btn-raised btn-default btn-back" (click)="back()">Back</button>
      <button class="btn btn-raised btn-default btn-next" [disabled]="loginMethodForm.status !== 'VALID'" (click)="save()">
        Save & go next
      </button>
    </div>
  </div>
</div>
