import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SummaryService } from './summary.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingComponentsService } from '../../../../core/services/loading/loadingComponents.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmReleaseModalComponent } from './confirm-release-modal/confirm-release-modal.component';
import { ApplicationsService } from '../../../applications/applications.service';
import { SummaryData } from './summary.model';
import { ConfirmHideModalComponent } from './confirm-hide-modal/confirm-hide-modal.component';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  summaryData: SummaryData;
  applicationId: string;

  @Input() isReleased: boolean;

  constructor(private summaryService: SummaryService,
              private loadingComponentsService: LoadingComponentsService,
              public dialog: MatDialog,
              private applicationsService: ApplicationsService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    this.summaryData = this.route.snapshot.data.stepData;
    this.applicationId = this.route.snapshot.params.id;
  }

  back() {
    this.router.navigateByUrl(`/admin/applicationsSetup/5/${this.applicationId}`);
  }

  openConfirmActionModal(type: string) {
    let dialogRef;
    if (type === 'release') {
      dialogRef = this.dialog.open(ConfirmReleaseModalComponent);
    } else if (type === 'hide') {
      dialogRef = this.dialog.open(ConfirmHideModalComponent);
    }

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.changeAppStatus();
      }
    });
  }

  changeAppStatus() {
    this.summaryService.changeStatus(this.applicationId)
      .subscribe(() => {
        this.applicationsService.updateAppList();
        this.router.navigateByUrl('/admin/applications');
      });
  }
}
