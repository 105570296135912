import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { ValidationData } from './validation.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(private httpClient: HttpClient) {
  }

  getValidationData(id: string): Observable<ValidationData> {
    const apiUrl = environment.api + `applications/verification/${id}`;

    return this.httpClient
      .get<ValidationData>(apiUrl);
  }

  updateValidationData(id: string, data: ValidationData): Observable<Response> {
    const apiUrl = environment.api + `applications/verification/${id}`;

    return this.httpClient
      .post<Response>(apiUrl, data);
  }
}
