<div class="apps-wrapper">
    <table class="table table-striped table-hover table-sm" id="applicationsTable">
        <thead>
            <tr>
                <th>#</th>
                <th>Country</th>
                <th>Status</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of applications | sort:'name', let index = index">
                <td>{{ index+1 }}</td>
                <td>{{ item.name }}</td>
                <td [class.greenColor]="item.released">{{ item.released ? 'RELEASED' : 'DRAFT' }}</td>
                <td>
                    <button class="btn btn-info" [routerLink]="['../applicationsSetup', 6, item.id]">EDIT</button>
                    <button mat-button (click)="confirmDeletingApplication(item)" class="btn btn-outline-danger">DELETE</button>
                </td>
            </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4">
              <button *ngIf="isMain" class="btn btn-success pull-right" [routerLink]="['../applicationsSetup', 1]">ADD NEW</button>
            </td>
          </tr>
        </tfoot>
    </table>
    <div class="no-available" *ngIf="!applications">
        <h3>Once the application is created by the admin, it will be displayed here.</h3>
        <span>In case any issue appears, contact
            <a href="mailto:basel.sms-integration@roche.com" target="_blank">basel.sms-integration@roche.com</a>
        </span>
    </div>
</div>
