<div class="login">
  <div class="login__header">
    <img class="login__header-mv-logo" src="/assets/images/mv.png"/>
    <img class="login__header-roche-logo" src="/assets/images/roche-blue.png"/>
  </div>
  <div class="login__content">
    <p>Login with Roche Google Account</p>
    <div id="googleLoginButton"></div>
  </div>
  <div class="login__disclaimer">
    <p>I acknowledge that I am bound by confidentiality obligations imposed through my employment or contractual
      agreement
      with Roche in connection with my access to confidential information, including Mobile Verification and its
      contents. By
      entering Mobile Verification, I confirm that I understand that my activities within Mobile Verification may be
      monitored consistent
      with local law, and all contents and passwords are confidential information, and that unauthorized disclosure or
      use of
      such confidential information may result in disciplinary action including termination of my employment or services
      and/or
      legal action based on local law.</p>
  </div>
</div>
