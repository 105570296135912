import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { UserManagementComponent } from './home/user-management/user-management.component';
import { HomeModule } from './home/home.module';
import { VerifyEmailComponent } from './verify-email/verify-email.component';

@NgModule({
  declarations: [AppComponent, UserManagementComponent, VerifyEmailComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NoopAnimationsModule,
        SharedModule,
        CoreModule,
        AuthModule,
        HomeModule,
    ],
  bootstrap: [AppComponent],
})
export class AppModule {}
