import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Oauth2Service } from 'src/app/core/services';

@Injectable()
export class LoginService {
  constructor(
    private oauth2Service: Oauth2Service,
    private httpClient: HttpClient
  ) {}

  onSignIn(jwtToken) {
    const requestArray = [
      this.oauth2Service.setAuthToken(jwtToken),
      this.oauth2Service.setProfile(jwtToken),
    ];

    return Promise.all(requestArray).then(() => {
      return this.sso();
    });
  }

  sso() {
    const apiUrl = environment.api + 'auth/sso';

    return this.httpClient
      .get(apiUrl)
      .toPromise()
      .then((response: any) => {
        this.oauth2Service.updateProfile(response);
      });
  }
}
