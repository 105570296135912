<div class="medicine-modal">
  <h2 mat-dialog-title>Add New Medicine</h2>
  <form [formGroup]="medicineForm">
    <div mat-dialog-content class="mat-typography">
      <mat-form-field class="full-width">
        <input
          matNativeControl
          placeholder="GTIN"
          name="GTIN"
          formControlName="gtin"
          required
        />
      </mat-form-field>
      <span class="text-danger" *ngIf="gtin.invalid && (gtin.dirty || gtin.touched)">
      GTIN number format is invaild. Only numeric values are allowed.
    </span>

      <mat-form-field class="full-width">
        <input
          type="text"
          matNativeControl
          placeholder="Medicine Name"
          name="Medicine Name"
          formControlName="name"
          required
        />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Description</mat-label>
        <textarea formControlName="description" maxlength="300" rows="5" matNativeControl></textarea>
        <span>{{ description.value?.length ? description.value.length : 0 }} / 300</span>
      </mat-form-field>
      <label *ngIf="!!medicine.photoUrl">Uploading new image will overwrite current one.</label>
      <div *ngIf="!!medicine.photoUrl" class="preview-image center">
        <img src="{{medicine.photoUrl}}"/>
      </div>

      <label>Attach file</label>
      <ngx-file-drag-drop
        [accept]="'image/*'"
        [emptyPlaceholder]="'Drag & drop image'"
        (valueChanged)="onValueChange($event)"
      >
      </ngx-file-drag-drop>
      <span *ngIf="!fileIsValid">File format is invalid. Please choose an image.</span>

      <mat-form-field class="full-width">
        <input
          type="text"
          matInput
          placeholder="External link"
          name="External link"
          formControlName="externalUrl"
        />
      </mat-form-field>
    </div>
    <div mat-dialog-actions align="end">
      <button class="btn btn-raised btn-default btn-next" [disabled]="medicineForm.invalid || !fileIsValid"
              (click)="addNew(false)">Save
      </button>
      <button class="btn btn-raised btn-default btn-next" [disabled]="medicineForm.invalid || !fileIsValid"
              (click)="addNew(true)">Save & Add New
      </button>
      <button class="btn btn-raised btn-default btn-back" mat-dialog-close>Cancel</button>
    </div>
  </form>
</div>
