<div *ngIf="userList.length > 0" class="filter-buttons">
  Filter list:
  <button mat-raised-button class="btn btn-raised" (click)="filterUsers('')">All</button>
  <button mat-raised-button class="btn btn-raised" (click)="filterUsers('Approved')">Approved</button>
  <button mat-raised-button class="btn btn-raised" (click)="filterUsers('Rejected')">Rejected</button>
</div>

<div class="user-management-wrapper">
  <mat-spinner [diameter]="50" *ngIf="isLoading"></mat-spinner>
  <div *ngIf="userList.length === 0 && !isLoading">No available users</div>

  <table *ngIf="userList.length > 0 && !isLoading" class="table" id="applicationsTable">
    <thead>
    <tr>
      <th>Name</th>
      <th>Email</th>
      <th>Email confirmed</th>
      <th>Status</th>
      <th>Registration Date</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of userList | sort:'fullName' | filterStatus:filterString">
      <td>{{item.fullName}}</td>
      <td>{{item.email}}</td>
      <td>{{item.emailConfirmed ? 'Yes' : 'No'}}</td>
      <td>{{item.status}}</td>
      <td>{{item.registrationDate}}</td>
      <td>
        <button *ngIf="item.status !== 'Approved'" mat-raised-button class="btn btn-raised btn-primary" (click)="approveUser(item)">Approve</button>
        <button *ngIf="item.status !== 'Rejected'" mat-raised-button class="btn btn-raised btn-danger" (click)="rejectUser(item)">Reject</button>
      </td>
    </tr>
    </tbody>
  </table>
</div>
