<div class="form-wrapper step3">
  <h3>Verification</h3>
  <ul>
    <li>
      <mat-checkbox [checked]="true" [disabled]="true">Serial Number</mat-checkbox>
    </li>
    <li *ngFor="let validation of validationData.verificationMethods">
      <mat-checkbox [(ngModel)]="validation.assigned">{{ validation.name }}</mat-checkbox>
    </li>
    <li>
      <mat-checkbox [(ngModel)]="validationData.multipleScansThreshold.assigned">Multiple scans of the same serial number</mat-checkbox>
    </li>
  </ul>
  <div class="message-input" *ngIf="validationData.multipleScansThreshold.assigned">
    Show negative verification result after
    <mat-form-field>
      <input matInput type="number" [(ngModel)]="validationData.multipleScansThreshold.value" (change)="limitThreshold()"/>
    </mat-form-field>
    scans
  </div>
  <div>
    <button class="btn btn-outline" (click)="openValidationMessageModal()">+ Add Message</button>
  </div>
  <button class="btn btn-raised btn-default btn-back" (click)="back()">Back</button>
  <button class="btn btn-raised btn-default btn-next" (click)="save()">
    Save & go next
  </button>
</div>
