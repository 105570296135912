import { Pipe, PipeTransform } from '@angular/core';
import { Medicine } from '../../home/applications-setup/applications-setup-steps/5-database/medicine.model';

@Pipe({
  name: 'filterMedicine',
  pure: false
})
export class FilterMedicinePipe implements PipeTransform {
  transform(items: Medicine[], filter: string): Medicine[] {
    if (!items || !filter) {
      return items;
    }

    return items.filter(item => {
      return item.gtin.indexOf(filter) !== -1 || item.name.indexOf(filter) !== -1 || item.description.indexOf(filter) !== -1;
    });
  }
}
