import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpContactsService } from './help-contacts.service';
import { ConfirmDeleteHelpContactModalComponent } from './confirm-delete-help-contact-modal/confirm-delete-help-contact-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { HelpContacts } from './help-contacts.model';

@Component({
  selector: 'app-help-contacts',
  templateUrl: './help-contacts.component.html',
  styleUrls: ['./help-contacts.component.scss']
})
export class HelpContactsComponent implements OnInit {
  helpContactsData: HelpContacts;
  applicationId: string;
  mainContactIndex: number;
  helpContactsForm = this.fb.group({
    registrationNotificationEmail: '',
    contactsArray: this.fb.array([], [Validators.required])
  });

  constructor(private helpContactsService: HelpContactsService,
              private router: Router,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.helpContactsData = this.route.snapshot.data.stepData;
    this.applicationId = this.route.snapshot.params.id;

    if (this.helpContactsData.contacts.length) {
      this.helpContactsData.contacts.forEach((contact, index) => {
        this.contactsArray.push(this.initHelpContactForm(contact.name, contact.email, contact.phone, contact.id, contact.primary));
        if (contact.primary) {
          this.mainContactIndex = index;
        }
      });
    }
    if (this.usesEmailLogin()) {
      this.helpContactsForm.get('registrationNotificationEmail')
        .setValidators([Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);
    }

    if (this.helpContactsData.notificationEmail.used) {
      this.helpContactsForm.patchValue({ registrationNotificationEmail: this.helpContactsData.notificationEmail.email });
    }
  }

  save() {
    this.helpContactsData.contacts = [];

    this.contactsArray.controls.forEach((contact, index) => {
      const helpContact = {
        name: '',
        email: '',
        phone: null,
        primary: false,
        id: null
      };

      for (const key in contact.value) {
        if (contact.value.hasOwnProperty(key)){
          helpContact[key] = contact.value[key];
        }
      }

      if (index === this.mainContactIndex) {
        helpContact.primary = true;
      }

      this.helpContactsData.contacts.push(helpContact);
    });

    if (this.usesEmailLogin()) {
      this.helpContactsData.notificationEmail.email = this.helpContactsForm.value.registrationNotificationEmail;
    }

    this.helpContactsService.updateContactsData(this.applicationId, this.helpContactsData)
      .subscribe(() => {
        this.router.navigateByUrl(`/admin/applicationsSetup/5/${this.applicationId}`);
      });
  }

  back() {
    this.router.navigateByUrl(`/admin/applicationsSetup/3/${this.applicationId}`);
  }

  addNewContactRow() {
    if (this.contactsArray.length > 0) {
      if (this.contactsArray.length < 5) {
        this.contactsArray.push(this.initHelpContactForm('', '', '', ''));
      }
    } else {
      this.contactsArray.push(this.initHelpContactForm('', '', '', ''));
      this.mainContactIndex = 0;
    }
  }

  deleteHelpContact(contact, index) {
    const dialogRef = this.dialog.open(ConfirmDeleteHelpContactModalComponent, {
      data: {
        contactName: contact.controls.name.value
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.contactsArray.removeAt(index);
        this.helpContactsData.contacts.splice(index, 1);
        if (this.mainContactIndex === index) {
          this.mainContactIndex = 0;
        }
      }
    });
  }

  changeMainContact(index: number) {
    this.mainContactIndex = index;
  }

  usesEmailLogin() {
    return this.helpContactsData.notificationEmail.used;
  }

  private initHelpContactForm(name = '', email = '', phone = '', id: string, primary = false) {
    return this.fb.group({
      name: [name, Validators.required],
      email: [email, [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phone: [phone, [Validators.required, Validators.pattern('[- +()0-9]+')]],
      id
    });
  }

  get contactsArray() {
    return this.helpContactsForm.get('contactsArray') as FormArray;
  }

  isPrimaryContact(index) {
    return index === this.mainContactIndex;
  }
}
