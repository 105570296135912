<section class="home-container">
  <div class="home">
    <h1>Create & set new application for your country</h1>
    <button
      [disabled]="!isMain"
      [routerLink]="['applicationsSetup', 1]"
      class="home__add-new-button btn btn-success"
    >
      ADD NEW
    </button>
  </div>
</section>
