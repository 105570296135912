import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DecommissionService {
  constructor(private httpClient: HttpClient) {}

  getDecommissionUserList() {
    const apiUrl = environment.api + 'decommission/users';

    return this.httpClient
      .get(apiUrl);
  }

  updateDecommissionUserList(decommissionUserList) {
    const apiUrl = environment.api + 'decommission/users';

    return this.httpClient
      .post(apiUrl, decommissionUserList);
  }
}
