import { Component, OnInit } from '@angular/core';
import { Oauth2Service } from '../core/services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  isMain: any;

  constructor(private oauth2Service: Oauth2Service) {}

  ngOnInit(): void {
    this.isMain = this.oauth2Service.getProfile().isMain;
  }
}
