import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './guards/auth.guard';
import { RequestInterceptor } from './interceptors/http-interceptor.interceptor';
import { HttpCancelService, LoadingService, Oauth2Service } from './services';
import { LoadingComponentsService } from './services/loading/loadingComponents.service';
import { ApplicationSetupResolver } from './resolvers/application-setup.resolver';
import { ReauthModalComponent } from './services/oauth2/reauth-modal/reauth-modal.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    ReauthModalComponent
  ],
  imports: [BrowserAnimationsModule, HttpClientModule, SharedModule],
  providers: [
    LoadingService,
    LoadingComponentsService,
    Oauth2Service,
    HttpCancelService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    AuthGuard,
    ApplicationSetupResolver,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        `$CoreModule has already been loaded. Import Core modules in the AppModule only.`
      );
    }
  }
}
