import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterStatus'
})

export class FilterStatusPipe implements PipeTransform {

  transform(input: Array<any>, term: string) {
    if (input && term) {
      return input.filter((item) => {
        return item.status === term;
      });
    }
    return input;
  }

}
