import { Component, OnInit } from '@angular/core';
import { DecommissionService } from './decommission.service';

@Component({
  selector: 'app-decommission',
  templateUrl: './decommission.component.html',
  styleUrls: ['./decommission.component.scss']
})
export class DecommissionComponent implements OnInit {
  isLoading = false;
  decommissionUserList = [];

  constructor(private decommissionService: DecommissionService) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.decommissionService.getDecommissionUserList()
      .subscribe((response: any) => {
        this.decommissionUserList = response;
        this.isLoading = false;
      });
  }

  save() {
    this.isLoading = true;
    return this.decommissionService.updateDecommissionUserList(this.decommissionUserList)
      .subscribe(() => {
        this.isLoading = false;
      });
  }
}
