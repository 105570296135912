import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationsSetupComponent } from './applications-setup/applications-setup.component';
import { HomeComponent } from './home.component';
import { ApplicationsComponent } from './applications/applications.component';
import { DecommissionComponent } from './decommission/decommission.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { ApplicationSetupResolver } from '../core/resolvers/application-setup.resolver';

const homeRoutes: Routes = [
  {path: '', component: HomeComponent},
  {
    path: 'applicationsSetup/:stepNumber',
    component: ApplicationsSetupComponent,
    resolve: {
      stepData: ApplicationSetupResolver
    }
  },
  {
    path: 'applicationsSetup/:stepNumber/:id',
    component: ApplicationsSetupComponent,
    resolve: {
      stepData: ApplicationSetupResolver
    }
  },
  {path: 'applications', component: ApplicationsComponent},
  {path: 'decommission', component: DecommissionComponent},
  {path: 'user-management', component: UserManagementComponent}
];

@NgModule({
  imports: [RouterModule.forChild(homeRoutes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {
}
