import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CountryLanguageService } from '../../home/applications-setup/applications-setup-steps/1-country-language/country-language.service';
import {
  RolesPermissionsService
} from '../../home/applications-setup/applications-setup-steps/2-roles-permissions/roles-permissions.service';
import { ValidationService } from '../../home/applications-setup/applications-setup-steps/3-validation/validation.service';
import { SummaryService } from '../../home/applications-setup/applications-setup-steps/6-summary/summary.service';
import { HelpContactsService } from '../../home/applications-setup/applications-setup-steps/4-help-contacts/help-contacts.service';
import { DatabaseService } from '../../home/applications-setup/applications-setup-steps/5-database/database.service';

@Injectable()
export class ApplicationSetupResolver implements Resolve<any> {
  constructor(private countryLanguageService: CountryLanguageService,
              private rolesPermissionsService: RolesPermissionsService,
              private validationService: ValidationService,
              private helpContactsService: HelpContactsService,
              private databaseService: DatabaseService,
              private summaryService: SummaryService) {}

  resolve(route: ActivatedRouteSnapshot) {
    if (!route.params.id) {
      return this.countryLanguageService.getCountryLanguageDataForNewCountry();
    } else if (route.params.stepNumber === '1') {
      return this.countryLanguageService.getCountryLanguageData(route.params.id);
    } else if (route.params.stepNumber === '2') {
      return this.rolesPermissionsService.getRolesPermissionsData(route.params.id);
    } else if (route.params.stepNumber === '3') {
      return this.validationService.getValidationData(route.params.id);
    } else if (route.params.stepNumber === '4') {
      return this.helpContactsService.getContactsData(route.params.id);
    } else if (route.params.stepNumber === '5') {
      return this.databaseService.getMedicineData(route.params.id);
    } else if (route.params.stepNumber === '6') {
      return this.summaryService.getSummaryData(route.params.id);
    }
  }
}
