import { Component, Inject, OnInit, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../../environments/environment';
import { Oauth2Service } from '../oauth2.service';
declare let google: any;

@Component({
  selector: 'app-reauth-modal',
  templateUrl: './reauth-modal.component.html'
})
export class ReauthModalComponent implements OnInit  {
  public client_id = environment.client_id;
  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private ngZone: NgZone,
              private dialogRef: MatDialogRef<ReauthModalComponent>,
              private oauth2Service: Oauth2Service) {}

  ngOnInit() {
    this.renderButton();
  }

  renderButton() {
    google.accounts.id.initialize({
      client_id: this.client_id,
      callback: this.onReAuth.bind(this)
    });
    google.accounts.id.renderButton(
      document.getElementById('googleLoginButton'),
      { theme: 'outline', size: 'medium' }
    );
  }

  onReAuth(token) {
    this.ngZone.run(() => {
      this.oauth2Service.handleReauth(token).then(() => {
        this.dialogRef.close(true);
      });
    });
  }
}
