import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient, HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/internal/operators';
import { HttpCancelService, Oauth2Service } from '../services';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private oauth2Service: Oauth2Service,
    private httpClient: HttpClient,
    private httpCancelService: HttpCancelService
  ) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const headers = request.headers
      .set('X-Auth-Token', this.oauth2Service.getAuthToken() || '');

    return next.handle(request.clone({headers})).pipe(
      takeUntil(this.httpCancelService.onCancelPendingRequests()),
      catchError((error) => {
        return this.handleResponseError(error);
      })
    );
  }
  handleResponseError(error: any): Observable<HttpEvent<unknown>> {
    if (error instanceof HttpErrorResponse && error.status === 401 && this.oauth2Service.isTokenExpired()) {
      this.oauth2Service.promptReAuth();
    }
    return throwError(() => new Error(error));
  }
}
