import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { VerifyEmailComponent } from './verify-email/verify-email.component';

const appRoutes: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      import('./layout/layout.module').then(({ LayoutModule }) => LayoutModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () =>
      import('./auth/auth.module').then(({ AuthModule }) => AuthModule),
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
