import { Component, NgZone, OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { Oauth2Service, LoadingService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';
declare let google: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public client_id = environment.client_id;
  constructor(
    private loginService: LoginService,
    private oauth2Service: Oauth2Service,
    private loadingService: LoadingService,
    private router: Router,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.renderButton();
  }

  renderButton() {
    google.accounts.id.initialize({
      client_id: this.client_id,
      callback: this.onSignIn.bind(this)
    });
    google.accounts.id.renderButton(
      document.getElementById('googleLoginButton'),
      { theme: 'outline', size: 'large' }  // customization attributes
    );
  }

  onSignIn(response) {
    this.loadingService.start();
    this.loginService
      .onSignIn(response.credential)
      .then(() => {
        this.loadingService.stop();
        this.ngZone.run(() => this.router.navigate(['/admin'])).then();
      })
      .catch(() => {
        this.loadingService.stop();
        this.navigateToAccessDenied();
      });
  }

  navigateToAccessDenied() {
    this.ngZone.run(() => this.router.navigate(['/accessDenied'])).then();
  }
}
