<div class="access-denied">
  <div class="access-denied__header">
    <img class="access-denied__header-mv-logo" src="/assets/images/mv.png" />
    <img
      class="access-denied__header-roche-logo"
      src="/assets/images/roche-blue.png"
    />
  </div>
  <div class="access-denied__content">
    You don't have permissions to use the application.
    <br />
    You can request an access for Mobile Application in <b>CIDM</b>.
    <a href="https://cidm.roche.com/" target="_blank"><button
      class="access-denied__content-request-access-button"
      mat-raised-button
      color="success-dark"
    >
      REQUEST ACCESS
    </button></a>
    <small>
      <p>In case any issue appears, contact basel.sms-integration@roche.com</p>
    </small>
  </div>
</div>
