import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from './validation.service';
import { MatDialog } from '@angular/material/dialog';
import { ValidationModalComponent } from './validation-modal/validation-modal.component';
import { ValidationData } from './validation.model';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit {
  validationData: ValidationData;
  applicationId: string;
  validationDataSnapshot: ValidationData;

  constructor(private validationService: ValidationService,
              private router: Router,
              public dialog: MatDialog,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.validationData = this.route.snapshot.data.stepData;
    this.applicationId = this.route.snapshot.params.id;
  }

  openValidationMessageModal() {
    this.validationDataSnapshot = JSON.parse(JSON.stringify(this.validationData)); // deep cloning
    const dialogRef = this.dialog.open(ValidationModalComponent, {
      data: {
        validationData: this.validationData
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event === 'cancel') {
        this.validationData = JSON.parse(JSON.stringify(this.validationDataSnapshot));
      }
    });
  }

  save() {
    this.validationService.updateValidationData(this.applicationId, this.validationData)
      .subscribe(() => this.router.navigateByUrl(`/admin/applicationsSetup/4/${this.applicationId}`));
  }

  back() {
    this.router.navigateByUrl(`/admin/applicationsSetup/2/${this.applicationId}`);
  }

  limitThreshold() {
    this.validationData.multipleScansThreshold.value = Math.max(Math.min(this.validationData.multipleScansThreshold.value, 99), 1);
  }
}
