<div class="documents-modal">
  <h2 mat-dialog-title>Upload {{modalType}} file</h2>
  <form [formGroup]="documentsGroup">
    <div mat-dialog-content class="mat-typography">
      <div *ngFor="let language of languages">
        <label><b>{{language.name}}</b></label>
        <div *ngIf="documents[findDocumentIndexByLanguage(language)]?.id">
          Current {{modalType}} is
          <a [href]="documents[findDocumentIndexByLanguage(language)].fileUrl"
             target="_blank">{{documents[findDocumentIndexByLanguage(language)].name}}</a>.
        </div>
        <ngx-file-drag-drop
          [accept]="'text/html'"
          formControlName="{{language.code}}"
          [emptyPlaceholder]="'Drag & drop file'"
        >
        </ngx-file-drag-drop>
      </div>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-button (click)="saveAssets()">Save & Close</button>
    </div>
  </form>
</div>
