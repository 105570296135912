import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CountryLanguageService } from './country-language.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DocumentsModalComponent } from '../documents-modal/documents-modal.component';
import { ApplicationsService } from '../../../applications/applications.service';
import { CountryLanguageData } from './country-language.model';
import { FormBuilder } from '@angular/forms';
import { FileValidators } from 'ngx-file-drag-drop';

@Component({
  selector: 'app-country-language',
  templateUrl: './country-language.component.html',
  styleUrls: ['./country-language.component.scss']
})
export class CountryLanguageComponent implements OnInit {
  countryLanguageData: CountryLanguageData = {
    chosenLanguages: [],
    documents: {
      terms: [],
      imprint: []
    }
  };
  documentsGroups = {
    terms: this.formBuilder.group({}),
    imprint: this.formBuilder.group({})
  };
  validationMessage = '';
  applicationId;

  constructor(private countryLanguageService: CountryLanguageService,
              private route: ActivatedRoute,
              private router: Router,
              private formBuilder: FormBuilder,
              private applicationsService: ApplicationsService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    Object.assign(this.countryLanguageData, this.route.snapshot.data.stepData);

    if (this.route.snapshot.params.id) {
      this.applicationId = this.route.snapshot.params.id;
      this.countryLanguageData.chosenLanguages.forEach((language) => {
        this.addLanguageToDocumentsGroup(language);
      });
    }
  }

  openModal(type: string) {
    this.dialog.open(DocumentsModalComponent, {
      data: {
        languages: this.countryLanguageData.chosenLanguages,
        documents: this.countryLanguageData.documents[type],
        documentsGroup: this.documentsGroups[type],
        type
      }
    });
  }

  save(): void {
    this.addDocumentsToSave('imprint');
    this.addDocumentsToSave('terms');

    if (!this.applicationId) {
      this.countryLanguageService.saveCountryLanguage(this.countryLanguageData)
        .subscribe((applicationId) => {
          this.applicationsService.updateAppList();
          this.router.navigateByUrl(`/admin/applicationsSetup/2/${applicationId.id}`);
        });
    } else {
      this.countryLanguageService.saveCountryLanguage(this.countryLanguageData, this.applicationId)
        .subscribe(() => {
          this.applicationsService.updateAppList();
          this.router.navigateByUrl(`/admin/applicationsSetup/2/${this.applicationId}`);
        });
    }
  }

  validateFirstStep(): boolean {
    this.validationMessage = '';
    let validation = true;
    const termsCounter = this.getDocumentAmount('terms');
    const imprintCounter = this.getDocumentAmount('imprint');

    if ((!this.countryLanguageData.chosenCountry?.id && !this.applicationId) || !this.countryLanguageData.chosenLanguages.length) {
      this.validationMessage += 'No country or language found.\n';
      validation = false;
    }

    if ((termsCounter > 0 && termsCounter < this.countryLanguageData.chosenLanguages.length) ||
      (imprintCounter > 0 && imprintCounter < this.countryLanguageData.chosenLanguages.length)) {
      this.validationMessage += 'Please upload all documents for chosen languages.\n';
      validation = false;
    }

    return validation;
  }

  addLanguageToDocumentsGroup(language): void {
    this.documentsGroups.imprint
      .addControl(language.code, this.formBuilder.control([], [FileValidators.required, FileValidators.maxFileCount(1)]));
    this.documentsGroups.terms
      .addControl(language.code, this.formBuilder.control([], [FileValidators.required, FileValidators.maxFileCount(1)]));
  }

  removeLanguageFromDocumentsGroup(language): void {
    this.documentsGroups.imprint.removeControl(language.code);
    this.documentsGroups.terms.removeControl(language.code);
  }

  compareObjectsById(listedObject, selectedObject): boolean {
    return listedObject.id === selectedObject.id;
  }

  private addDocumentsToSave(type) {
    this.countryLanguageData.documents[type] = [];
    if (this.getNewDocumentAmount(type) > 0) {
      this.countryLanguageData.chosenLanguages.forEach((language) => {
        if (this.documentsGroups[type].value[language.code]?.[0]) {
          this.countryLanguageData.documents[type].push({
            languageId: language.id,
            fileName: this.countryLanguageData.chosenCountry.code + '-' + language.code + '-' + type + '-' + this.documentsGroups[type].value[language.code][0].name,
            file: this.documentsGroups[type].value[language.code][0]
          });
        }
      });
    }
  }

  private getDocumentAmount(type: string): number {
    let counter = 0;

    this.countryLanguageData.chosenLanguages.forEach((language) => {
      if (this.documentsGroups[type].value[language.code]?.length) {
        counter += this.documentsGroups[type].value[language.code]?.length;
      } else {
        const documentIndex = this.countryLanguageData.documents[type].findIndex((element) => {
          return element.language?.id === language.id;
        });
        if (documentIndex !== -1) {
          counter++;
        }
      }
    });

    return counter;
  }

  private getNewDocumentAmount(type: string): number {
    let counter = 0;

    this.countryLanguageData.chosenLanguages.forEach((language) => {
      counter += this.documentsGroups[type].value[language.code]?.length || 0;
    });

    return counter;
  }
}
