import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingComponentsService } from '../../core/services/loading/loadingComponents.service';
import { Subscription } from 'rxjs/index';
import { ApplicationsSetupService } from './applications-setup.service';

@Component({
  selector: 'app-applications-setup',
  templateUrl: './applications-setup.component.html',
  styleUrls: ['./applications-setup.component.scss'],
})
export class ApplicationsSetupComponent implements OnInit, AfterViewInit {
  isReleased = false;
  applicationId;
  loading = false;
  stepNumber: number;

  private loading$: Subscription;

  constructor(private loadingComponentsService: LoadingComponentsService,
              private applicationsSetupService: ApplicationsSetupService,
              private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.stepNumber = Number(params.stepNumber);
    });
  }

  ngOnInit() {
    this.applicationId = this.route.snapshot.params.id;
    if (this.applicationId) {
      this.applicationsSetupService.getApplication(this.applicationId)
        .subscribe((app) => {
          this.isReleased = app.released;
        });
    }
  }

  ngAfterViewInit(): void {
    this.loading$ = this.loadingComponentsService.loadingComponent$
      .pipe()
      .subscribe((status: boolean) => {
        this.loading = status;
      });
  }

}
