<div class="form-wrapper step6">
  <h3>Before you finish. Please check all information.</h3>
  <div class="row row-summary">
    <div class="col-md-6">
      <h4>Country & Language <i class="fa fa-pencil" [routerLink]="['../../../applicationsSetup', 1, applicationId]"
                                aria-hidden="true"></i></h4>
      <h5>Country</h5>
      <span>{{summaryData.countryName}}</span>
      <h5>Application Language</h5>
      <div>
              <span>
                {{ summaryData.applicationLanguages.join(', ') }}
              </span>
      </div>
    </div>

    <div class="col-md-6">
      <h4>Roles & Permissions <i [routerLink]="['../../../applicationsSetup', 2, applicationId]" class="fa fa-pencil"
                                 aria-hidden="true"></i></h4>
      <div *ngFor="let role of summaryData.rolesAndPermissions">
        <h5>{{ role.roleName }}</h5>
        <div>
          <span *ngFor="let permission of role.permissions">{{ permission }}<br/></span>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-summary">
    <div class="col-md-6">
      <h4>Validation <i class="fa fa-pencil" [routerLink]="['../../../applicationsSetup', 3, applicationId]" aria-hidden="true"></i></h4>
      <h5>Verification</h5>
      <div>
        <span>Serial number<br/></span>
        <span *ngFor="let item of summaryData.validation">
                  {{ item }}<br/>
        </span>
      </div>
    </div>
    <div class="col-md-6">
      <h4>Database <i class="fa fa-pencil" [routerLink]="['../../../applicationsSetup', 5, applicationId]" aria-hidden="true"></i></h4>
      <span>{{ summaryData.medicineCount }} medicines added</span>
    </div>
  </div>
  <div class="row row-summary">
    <div class="col-md-12">
      <h4>Help Contacts <i class="fa fa-pencil" [routerLink]="['../../../applicationsSetup', 4, applicationId]" aria-hidden="true"></i>
      </h4>
      <table class="table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Phone number</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let contact of summaryData.contacts, let i = index">
          <td>
            {{contact.name}}
          </td>
          <td>
            {{contact.email}}
          </td>
          <td>
            {{contact.phone}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row row-summary" *ngIf="summaryData?.notificationEmail.used">
    <div class="col-md-12">
      <h5>Notify about new user registration</h5>
      <span>{{summaryData.notificationEmail.email}}</span>
    </div>
  </div>
  <div *ngIf="!summaryData.canBeReleased">{{ summaryData.invalidFormMessage.join(', ') }}</div>
  <button class="btn btn-raised btn-danger btn-save button-margin" mat-raised-button *ngIf="isReleased"
          (click)="openConfirmActionModal('hide')">Hide application
  </button>
  <button class="btn btn-raised btn-default btn-info btn-next button-margin" mat-raised-button *ngIf="!isReleased"
          (click)="openConfirmActionModal('release')" [disabled]="!summaryData.canBeReleased">Save and release
  </button>
  <button class="btn btn-raised btn-default btn-back" mat-raised-button (click)="back()">Back</button>
</div>
