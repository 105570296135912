import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { RolesPermissionsData } from './roles-permissions.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RolesPermissionsService {

  constructor(private httpClient: HttpClient) { }

  getRolesPermissionsData(id: string): Observable<RolesPermissionsData> {
    const apiUrl = environment.api + `applications/roles-and-permissions/${id}`;

    return this.httpClient
      .get<RolesPermissionsData>(apiUrl);
  }

  updateRoles(id: string, body): Observable<Response> {
    const apiUrl = environment.api + `applications/roles-and-permissions/${id}`;

    const fd = new FormData();

    body.forEach((loginMethod) => {
      if (loginMethod.collectUserData && loginMethod.collectDataDocuments.length > 0) {
        loginMethod.collectDataDocuments.forEach((el) => {
          fd.append('files', el.file, el.fileName);
        });
      }
    });

    const str = JSON.stringify(body);
    const bytes = new TextEncoder().encode(str);
    const blob = new Blob([bytes], {
      type: 'application/json;charset=utf-8'
    });

    fd.append('dtos', blob);

    return this.httpClient
      .post<Response>(apiUrl, fd);
  }
}
