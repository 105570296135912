import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RolesPermissionsService } from './roles-permissions.service';
import { MatDialog } from '@angular/material/dialog';
import { RolesPermissionsData } from './roles-permissions.model';
import { DocumentsModalComponent } from '../documents-modal/documents-modal.component';
import { FormBuilder, FormGroup, FormArray, AbstractControl, ValidationErrors } from '@angular/forms';
import { FileValidators } from 'ngx-file-drag-drop';
import { takeUntil } from 'rxjs/internal/operators';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-roles-permissions',
  templateUrl: './roles-permissions.component.html',
  styleUrls: ['./roles-permissions.component.scss']
})
export class RolesPermissionsComponent implements OnInit, OnDestroy {
  rolesPermissionsData: RolesPermissionsData;
  applicationId: string;
  loginMethodForm: FormGroup;
  documents = {};
  private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              public dialog: MatDialog,
              private rolesPermissionsService: RolesPermissionsService) {
  }

  ngOnInit(): void {
    this.rolesPermissionsData = this.route.snapshot.data.stepData;
    this.applicationId = this.route.snapshot.params.id;

    this.rolesPermissionsData.loginMethods.forEach((loginMethod) => {
      this.documents[loginMethod.userRole.name] = [];
      if (loginMethod.collectUserData === true) {
        this.documents[loginMethod.userRole.name] = loginMethod.collectDataDocuments;
      }
    });

    this.initializeLoginMethodForm();
  }

  save() {
    this.submitRoles()
      .subscribe(() => this.router.navigateByUrl(`/admin/applicationsSetup/3/${this.applicationId}`));
  }

  back() {
    this.router.navigateByUrl(`/admin/applicationsSetup/1/${this.applicationId}`);
  }

  submitRoles() {
    const rolesPermissionsObject = [];

    const formValues = this.loginMethodForm.value.loginMethods;

    formValues.forEach((loginMethod) => {
      if (loginMethod.userRole.assigned) {
        const roleObject = {
          userRoleId: '',
          authenticationProviderIds: [],
          collectUserData: false,
          collectDataDocuments: []
        };

        roleObject.userRoleId = loginMethod.userRole.id;

        loginMethod.authenticationProviders.forEach((provider) => {
          if (provider.assigned) {
            roleObject.authenticationProviderIds.push(provider.id);
          }
        });

        if (loginMethod.collectUserData) {
          roleObject.collectUserData = true;

          this.rolesPermissionsData.languages.forEach((language) => {
            if (loginMethod.collectDataDocuments[language.code]?.[0]) {
              roleObject.collectDataDocuments.push({
                languageId: language.id,
                fileName: this.route.snapshot.params.id + '-' + loginMethod.userRole.name + '-' + language.code + '-Privacy-Policy-' + loginMethod.collectDataDocuments[language.code][0].name,
                file: loginMethod.collectDataDocuments[language.code][0]
              });
            }
          });
        }

        rolesPermissionsObject.push(roleObject);
      }
    });

    return this.rolesPermissionsService.updateRoles(this.route.snapshot.params.id, rolesPermissionsObject);
  }

  openPrivacyModal(collectDataDocuments: AbstractControl, userRoleName) {
    this.dialog.open(DocumentsModalComponent, {
      data: {
        languages: this.rolesPermissionsData.languages,
        documents: this.documents[userRoleName],
        documentsGroup: collectDataDocuments,
        type: 'Privacy Policy'
      }
    });
  }

  private initializeLoginMethodForm() {
    this.loginMethodForm = this.formBuilder.group({
      loginMethods: this.createLoginMethodsArray()
    });

    this.updateValidatorsForAllLoginMethods();
  }
  private createLoginMethodsArray(): FormArray {
    return this.formBuilder.array(
      this.rolesPermissionsData.loginMethods.map(loginMethod => this.createLoginMethodGroup(loginMethod)),
      { validators: (control: FormArray) => this.combinedValidators(control) }
    );
  }

  private createLoginMethodGroup(loginMethod): FormGroup {
    return this.formBuilder.group({
      userRole: this.createUserRoleGroup(loginMethod.userRole),
      authenticationProviders: this.createAuthenticationProvidersArray(loginMethod.authenticationProviders),
      collectUserData: this.formBuilder.control(loginMethod.collectUserData),
      collectDataDocuments: this.createCollectDataDocumentsGroup()
    });
  }

  private createUserRoleGroup(userRole): FormGroup {
    return this.formBuilder.group({
      name: [userRole.name],
      assigned: [userRole.assigned],
      id: [userRole.id]
    });
  }

  private createAuthenticationProvidersArray(authenticationProviders): FormArray {
    return this.formBuilder.array(
      authenticationProviders.map(provider => this.createAuthProviderGroup(provider))
    );
  }

  private createAuthProviderGroup(provider): FormGroup {
    return this.formBuilder.group({
      name: [provider.name],
      assigned: [provider.assigned],
      id: [provider.id]
    });
  }

  private createCollectDataDocumentsGroup(): FormGroup {
    return this.formBuilder.group(
      this.rolesPermissionsData.languages.reduce((controls, language) => {
        controls[language.code] = this.formBuilder.control([]);
        return controls;
      }, {})
    );
  }

  private updateValidatorsForAllLoginMethods() {
    const loginMethodsArray = this.loginMethodForm.get('loginMethods') as FormArray;
    loginMethodsArray.controls.forEach((_, index) => {
      this.updateCollectDataDocumentsValidators(index);
      _.get('collectUserData').valueChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
        this.updateCollectDataDocumentsValidators(index);
      });
    });
  }

  loginMethodAssignedValidator(formArray: FormArray): ValidationErrors | null {
    const atLeastOneMethodAssigned = formArray.controls.some(control => control.get('userRole.assigned').value);
    return atLeastOneMethodAssigned ? null : { atLeastOneLoginMethodRequired: true };
  }

  roleAssignedValidator(formArray: FormArray): ValidationErrors | null {
    const allValid = formArray.controls.every(control => {
      const userRoleAssigned = control.get('userRole.assigned').value;
      const authProviders = control.get('authenticationProviders') as FormArray;
      const atLeastOneRoleAssigned = authProviders.controls.some(authProvider => authProvider.get('assigned').value);
      return !userRoleAssigned || atLeastOneRoleAssigned;
    });
    return allValid ? null : { atLeastOneRoleRequired: true };
  }

  collectDataDocumentsValidator(formArray: FormArray): ValidationErrors | null {
    const allValid = formArray.controls.every(control => {
      const userRoleName = control.get('userRole.name').value;
      const collectUserData = control.get('collectUserData').value;
      const collectDataDocuments = control.get('collectDataDocuments') as FormGroup;

      // Check if all language documents are present either in the upload form or in the documents object
      const allDocumentsPresent = Object.keys(collectDataDocuments.controls).every(languageCode => {
        const isUploaded = collectDataDocuments.controls[languageCode].valid;
        const isExisting = this.documents[userRoleName] && this.documents[userRoleName].some(doc => doc.language.code === languageCode);
        return isUploaded || isExisting;
      });

      return !collectUserData || allDocumentsPresent;
    });

    return allValid ? null : { documentRequired: true };
  }

  combinedValidators(formArray: FormArray): ValidationErrors | null {
    return this.loginMethodAssignedValidator(formArray) || this.roleAssignedValidator(formArray) || this.collectDataDocumentsValidator(formArray);
  }

  updateCollectDataDocumentsValidators(index: number) {
    const loginMethodsArray = this.loginMethodForm.get('loginMethods') as FormArray;
    const collectUserDataControl = loginMethodsArray.at(index).get('collectUserData');
    const userRoleNameControl = loginMethodsArray.at(index).get('userRole.name');
    const collectDataDocumentsGroup = loginMethodsArray.at(index).get('collectDataDocuments') as FormGroup;

    if (collectUserDataControl.value) {
      Object.keys(collectDataDocumentsGroup.controls).forEach(languageCode => {
        const isExisting = this.documents[userRoleNameControl.value] && this.documents[userRoleNameControl.value].some(doc => doc.language.code === languageCode);
        if (!isExisting) {
          collectDataDocumentsGroup.get(languageCode).setValidators([FileValidators.required, FileValidators.maxFileCount(1)]);
        } else {
          collectDataDocumentsGroup.get(languageCode).setValidators([FileValidators.maxFileCount(1)]);
        }
        collectDataDocumentsGroup.get(languageCode).updateValueAndValidity();
      });
    } else {
      Object.keys(collectDataDocumentsGroup.controls).forEach(languageCode => {
        collectDataDocumentsGroup.get(languageCode).setValidators(null);
        collectDataDocumentsGroup.get(languageCode).updateValueAndValidity();
      });
    }

    this.loginMethodForm.updateValueAndValidity();
  }

  public ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  get loginMethods(): FormArray {
    return this.loginMethodForm.get('loginMethods') as FormArray;
  }
}
