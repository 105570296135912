import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReauthModalComponent } from './reauth-modal/reauth-modal.component';
import { Router } from '@angular/router';
declare let google: any;

@Injectable()
export class Oauth2Service {
  authToken: any;
  profile: any;

  constructor(public dialog: MatDialog,
              private router: Router) {}

  setAuthToken(jwtToken) {
    return new Promise((resolve) => {
      this.authToken = jwtToken;
      resolve(this.authToken);
    });
  }

  getAuthToken() {
    return this.authToken;
  }

  getProfile() {
    return this.profile;
  }

  updateProfile(profile) {
    return new Promise((resolve) => {
      this.profile.isMain = profile.main;
      this.profile.countries = profile.countries;
      resolve(this.profile);
    });
  }

  setProfile(token) {
    const tokenData = this.decodeJwtToken(token);
    return new Promise((resolve) => {
      this.profile = {
        name: tokenData.given_name + ' ' + tokenData.family_name,
        picture: tokenData.picture,
        email: tokenData.email,
        exp: tokenData.exp
      };
      resolve(this.profile);
    });
  }

  decodeJwtToken(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
  }

  isTokenExpired() {
    return this.profile.exp * 1000 - new Date().getTime() <= 10;
  }

  promptReAuth() {
    const dialogRef = this.dialog.open(ReauthModalComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== true) {
        this.signOut().then(() => {
          this.router.navigateByUrl('/login');
        });
      }
    });
  }

  handleReauth(jwtToken) {
    this.authToken = jwtToken.credential;
    return this.setProfile(jwtToken.credential);
  }

  isLoggedIn(): boolean {
    return !!this.profile;
  }

  signOut() {
    this.profile = null;
    return new Promise((resolve) => {
      resolve(google.accounts.id.disableAutoSelect());
    });
  }
}
