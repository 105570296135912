<div class="form-wrapper step5">
  <p class="search-label">Search</p>
  <mat-form-field class="medicine-search">
    <input
      type="text"
      matInput
      placeholder="eg. 12346, Actemra"
      name="searchValue"
      [(ngModel)]="medicineSearch"
    />
  </mat-form-field>
  <i class="fa fa-search" aria-hidden="true"></i>
  <table class="table">
      <thead>
          <tr>
              <th>GTIN#</th>
              <th>Medicine</th>
              <th>Description</th>
              <th>Photo</th>
              <th></th>
              <th></th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let medicine of medicinesData | filterMedicine: medicineSearch">
              <td>
                  {{medicine.gtin | slice:0:20 }}{{medicine.gtin.length > 20 ? '...' : ''}}
              </td>
              <td>
                  {{medicine.name | slice:0:20 }}{{medicine.name.length > 20 ? '...' : ''}}
              </td>
              <td>
                  {{medicine?.description | slice:0:20 }}{{medicine.description?.length > 20 ? '...' : ''}}
              </td>
              <td>
                 <a *ngIf="medicine.photoUrl?.length" href="{{medicine.photoUrl}}" target="_blank" class="btn btn-info">Preview</a>
              </td>
              <td>
                  <button mat-button (click)="openModal(medicine)" class="btn btn-info">EDIT</button>
              </td>
              <td>
                  <button mat-button (click)="deleteMedicine(medicine)" class="btn btn-danger">DELETE</button>
              </td>
          </tr>
      </tbody>
  </table>
  <button mat-raised-button color="success" class="btn btn-raised btn-default btn-new-medicine" (click)="openModal()">Add new</button>
  <div class="clear"></div>
  <button mat-raised-button class="btn btn-raised btn-default btn-back" (click)="back()">Back</button>
  <button mat-raised-button class="btn btn-raised btn-default btn-next" [disabled]="!validateDatabase()" (click)="save()">
    Save & go next
  </button>
</div>
