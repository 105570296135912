import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApplicationsService {
  applicationListSubject = new BehaviorSubject([]);

  constructor(private httpClient: HttpClient) {
  }

  public updateAppList() {
    this.getApplications()
      .subscribe((apps) => {
        this.applicationListSubject.next(apps.content);
      });
  }

  public getApplicationList(): Observable<Array<any>> {
    return this.applicationListSubject.asObservable();
  }

   getApplications() {
    const apiUrl = environment.api + 'applications';

    return this.httpClient
      .get<any>(apiUrl);
  }

  deleteApp(item) {
    const apiUrl = environment.api + `applications/${item.id}`;

    return this.httpClient
      .delete(apiUrl);
  }
}
