<div class="decommission-wrapper">
    <div *ngIf="decommissionUserList.length === 0">No available users</div>

    <table *ngIf="decommissionUserList.length > 0" class="table" id="applicationsTable">
        <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Primary Pack Decommission</th>
                <th>Secondary Pack Decommission</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of decommissionUserList | sort:'name'">
                <td>{{item.fullName}}</td>
                <td>{{item.email}}</td>
                <td>
                    <mat-checkbox [(ngModel)]="item.boxDecommissionAvailable"></mat-checkbox>
                </td>
                <td>
                    <mat-checkbox [(ngModel)]="item.packageDecommissionAvailable"></mat-checkbox>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <td colspan="4">
                <button mat-raised-button class="btn btn-raised btn-info btn-save pull-right" [disabled]="isLoading" (click)="save()">SAVE</button>
            </td>
        </tfoot>
    </table>
</div>
