import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SummaryData } from './summary.model';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SummaryService {

  constructor(private httpClient: HttpClient) {
  }

  getSummaryData(id: string): Observable<SummaryData> {
    const apiUrl = environment.api + `applications/summary/${id}`;

    return this.httpClient
      .get<SummaryData>(apiUrl);
  }

  changeStatus(id: string): Observable<Response> {
    const apiUrl = environment.api + `applications/${id}/status`;

    return this.httpClient
      .post<Response>(apiUrl, {});
  }
}
