import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-validation-modal',
  templateUrl: './validation-modal.component.html',
  styleUrls: ['./validation-modal.component.scss']
})
export class ValidationModalComponent {


  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<ValidationModalComponent>) {
  }

  saveAssets() {
    this.dialogRef.close();
  }

  checkIfMessageExists() {
    let validationFlag = true;

    this.data.validationData.verificationMessages.forEach((message) => {
      if (!message.positive.length || !message.negative.length) {
        validationFlag = false;
      }
    });

    return validationFlag;
  }

  cancel() {
    this.dialogRef.close({event: 'cancel'});
  }
}
