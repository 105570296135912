// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: 'https://api.mobileverification-dev.roche.com/',
  client_id:
    '542733778664-ohtul44e2e850ulirpe49asoh44qait7.apps.googleusercontent.com',
  scope: 'email profile openid',
  response_type: 'id_token permission',
  x_auth_provider: '51b85104-47be-4939-aafc-654d21f39e1c',
  AWS: {
    region: 'eu-central-1',
    IdentityPoolId: 'eu-central-1:e9f6298a-34f4-4efa-9341-cd3afdbe8d5a',
    images: {
      bucket: 'mv-medicines-images',
      url: 'https://s3.eu-central-1.amazonaws.com/mv-medicines-images'
    }
  },
  bucket: 'sbx'
};
