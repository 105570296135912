import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HelpContacts } from './help-contacts.model';

@Injectable({
  providedIn: 'root'
})
export class HelpContactsService {

  constructor(private httpClient: HttpClient) {
  }

  getContactsData(id: string): Observable<HelpContacts> {
    const apiUrl = environment.api + `applications/contact/${id}`;

    return this.httpClient
      .get<HelpContacts>(apiUrl);
  }

  updateContactsData(id: string, data: HelpContacts): Observable<Response> {
    const apiUrl = environment.api + `applications/contact/${id}`;

    return this.httpClient
      .post<Response>(apiUrl, data);
  }
}
