import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatabaseService } from '../database.service';
import { Validators, FormBuilder } from '@angular/forms';
import { Medicine } from '../medicine.model';

@Component({
  selector: 'app-medicine-modal',
  templateUrl: './medicine-modal.component.html',
  styleUrls: ['./medicine-modal.component.scss']
})
export class MedicineModalComponent implements OnInit {
  medicine: Medicine = {
    id: null,
    gtin: null,
    name: '',
    description: '',
    externalUrl: '',
    photoUrl: '',
  };
  medicineForm = this.fb.group({
    gtin: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
    name: ['', Validators.required],
    description: [''],
    externalUrl: [''],
  });
  file: File;
  fileIsValid = true;
  index: any;
  medicinesData: Medicine[];
  applicationId: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private databaseService: DatabaseService,
              private fb: FormBuilder,
              public dialogRef: MatDialogRef<MedicineModalComponent>) {
  }

  ngOnInit() {
    this.medicinesData = this.data.medicinesData;
    this.applicationId = this.data.applicationId;
    this.index = this.data.index;

    if (this.index !== null) {
      for (const key in this.medicine) {
        if (this.medicinesData[this.index][key]) {
          this.medicine[key] = this.medicinesData[this.index][key];
        }
      }

      this.medicineForm.setValue({
        gtin: this.medicine.gtin,
        name: this.medicine.name,
        description: this.medicine.description,
        externalUrl: this.medicine.externalUrl,
      });
    }
  }

  addNew(addNew: boolean) {
    Object.assign(this.medicine, this.medicineForm.value);

    this.databaseService.saveMedicine(this.applicationId, this.medicine, this.file)
      .subscribe((response) => {
        if (this.index === null) {
          this.medicinesData.push(response);
        } else {
          this.medicinesData[this.index] = response;
        }
        this.dialogRef.close(addNew);
      }, (err) => {
        switch (err.status) {
          case 422: alert(err.error.message); break;
          default: alert('Failed to save medicine.');
        }
      });
  }

  onValueChange(file: File[]) {
    this.file = file[0];
    this.fileIsValid = this.file === undefined ? true : this.file.type.includes('image');
  }

  get gtin() {
    return this.medicineForm.get('gtin');
  }

  get name() {
    return this.medicineForm.get('name');
  }

  get description() {
    return this.medicineForm.get('description');
  }
}
