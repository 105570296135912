import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  constructor(private httpClient: HttpClient) {}

  getUserList() {
    const apiUrl = environment.api + `management/users`;
    const params = {
      page: 0,
      size: 1000
    };

    return this.httpClient
      .get<any>(apiUrl, {params});
  }

  approveUser(userId: string) {
    const apiUrl = environment.api + `management/users/${userId}/approve`;

    return this.httpClient
      .get<any>(apiUrl);
  }

  rejectUser(userId: string) {
    const apiUrl = environment.api + `management/users/${userId}/reject`;

    return this.httpClient
      .get<any>(apiUrl);
  }
}
