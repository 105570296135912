import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsSetupService {

  constructor(private httpClient: HttpClient) {
  }

  getApplication(id: string) {
    const apiUrl = environment.api + `applications/${id}`;

    return this.httpClient
      .get<any>(apiUrl);
  }
}
