import { NgModule } from '@angular/core';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginService } from './login/login.service';

@NgModule({
  declarations: [LoginComponent, AccessDeniedComponent],
  imports: [SharedModule, AuthRoutingModule],
  providers: [LoginService],
})
export class AuthModule {}
