import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { MaterialModule } from './material.module';
import { ArraySortPipe } from './pipes/array-sort.pipe';
import { FilterMedicinePipe } from './pipes/filter-medicine.pipe';

const sharedModules = [CommonModule, MaterialModule];

const sharedComponents = [LoaderComponent];

const sharedPipes = [ArraySortPipe, FilterMedicinePipe];

@NgModule({
  declarations: [
    sharedComponents,
    sharedPipes
  ],
  imports: [sharedModules],
  exports: [sharedModules, sharedComponents, sharedPipes],
})
export class SharedModule {
}
