import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-documents-modal',
  templateUrl: './documents-modal.component.html',
  styleUrls: ['./documents-modal.component.scss']
})
export class DocumentsModalComponent implements OnInit {
  modalType: string;
  languages: any = [];
  documents: any = [];
  documentsGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<DocumentsModalComponent>) {
  }

  ngOnInit(): void {
    this.modalType = this.data.type;
    this.languages = this.data.languages;
    this.documents = this.data.documents;
    this.documentsGroup = this.data.documentsGroup;
  }

  saveAssets() {
    this.dialogRef.close();
  }

  findDocumentIndexByLanguage(language) {
    return this.documents?.findIndex((element) => {
      return element.language?.id === language.id;
    });
  }
}
