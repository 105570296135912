<div class="privacy-modal">
  <h2 mat-dialog-title>Add Message</h2>
  <div mat-dialog-content class="mat-typography">
    <div class="modal-body step3-modal" id="modal-body">
      <div class="row">
        <div class="col-md-6">
          <span class="underline color-green">Positive</span> verification status message
          <div class="language-message-input" *ngFor="let message of data.validationData.verificationMessages">
            {{message.language.name}}
            <mat-form-field>
              <textarea [(ngModel)]="message.positive" maxlength="140" rows="3" matNativeControl></textarea>
              <span>{{ message.positive.length ? message.positive.length : 0 }} / 140</span>
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-6">
          <span class="underline color-red">Negative</span> verification status message
          <div class="language-message-input" *ngFor="let message of data.validationData.verificationMessages">
            {{message.language.name}}
            <mat-form-field>
              <textarea [(ngModel)]="message.negative" maxlength="140" rows="3" matNativeControl></textarea>
              <span>{{ message.negative.length ? message.negative.length : 0 }} / 140</span>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <span>Choose message colors</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="radio radio-primary">
            <label>
              <input type="radio" name="colorType" [value]="false" [(ngModel)]="data.validationData.verificationMessageNeutralColor" required><span class="circle"></span><span class="check"></span>
              <span class="negative-option"><i class="fa fa-times" aria-hidden="true"></i>Negative</span>
              <span class="positive-option"><i class="fa fa-check" aria-hidden="true"></i> Positive</span>
            </label>
          </div>
        </div>
        <div class="col-md-6">
          <div class="radio radio-primary">
            <label>
              <input type="radio" name="colorType" [value]="true" [(ngModel)]="data.validationData.verificationMessageNeutralColor"><span class="circle"></span><span class="check"></span>
              <span class="neutral-option">Neutral Positive & Negative</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button [disabled]="!checkIfMessageExists()" (click)="saveAssets()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </div>
</div>
